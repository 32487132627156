import SupportAgent from '@mui/icons-material/SupportAgent';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useAnchorElementState, useBooleanState } from '../../hooks';
import MenuOperativeRequest from './MenuOperativeRequest';

const RaiseOperativeRequestIconButton = ({
  selectedInvoices,
  clear,
  documentType,
}) => {
  const [hovered, , setTrue, setFalse] = useBooleanState(false);
  const [anchor, openAnchor, closeAnchor] = useAnchorElementState();

  return (
    <>
      <Tooltip title="¡Ayuda!">
        <span>
          <IconButton
            onClick={openAnchor}
            disabled={!selectedInvoices?.length}
            onMouseOver={setTrue}
            onMouseLeave={setFalse}
            color="primary"
            sx={{
              transition: 'transform 0.5s ease-in-out',
              transform: 'rotate(0deg)',
              ...(hovered && { transform: 'scale(1.2) rotate(10deg)' }),
            }}
          >
            <SupportAgent />
          </IconButton>
        </span>
      </Tooltip>
      <MenuOperativeRequest
        anchor={anchor}
        closeAnchor={closeAnchor}
        selectedInvoices={selectedInvoices}
        clear={clear}
        documentType={documentType}
      />
    </>
  );
};

RaiseOperativeRequestIconButton.propTypes = {
  documentType: PropTypes.string,
  clear: PropTypes.func,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
};

RaiseOperativeRequestIconButton.defaultProps = {
  selectedInvoices: [],
  clear: () => {},
  documentType: 'invoice',
};

export default RaiseOperativeRequestIconButton;
