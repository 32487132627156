import { Menu, MenuItem } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRaiseOperativeRequest, useBooleanState, useTextFieldInput } from '../../hooks';
import { DialogWithTextField } from '../../components/dialogs';
import { GET_OPERATIVE_REQUEST_TYPE } from '../../graphql';

const MenuOperativeRequest = ({
  selectedInvoices,
  clear,
  documentType,
  anchor,
  closeAnchor,
}) => {
  const [openDialog, toggleDialog] = useBooleanState();
  const [comment, setComment, resetComment] = useTextFieldInput();
  const [requestType, setRequestType] = useState({});
  const onCloseDialog = useCallback(() => { resetComment(); toggleDialog(); closeAnchor(); }, []);
  const { data } = useQuery(GET_OPERATIVE_REQUEST_TYPE);
  const requestTypeInfo = useMemo(() => (data ? data.getOperativeRequestType : []), [data]);

  const [
    _raiseOperativeRequest,
    loadingMutation,
  ] = useRaiseOperativeRequest(requestType.code, documentType, selectedInvoices, comment);

  const raiseOperativeRequest = useCallback(() => {
    _raiseOperativeRequest();
    onCloseDialog();
    clear();
  }, []);

  return (
    <>
      <Menu
        id="filter-menu-selector"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={closeAnchor}
      >
        {requestTypeInfo.map((requestInfo) => (
          <MenuItem
            id={requestInfo.code}
            key={requestInfo}
            sx={{ my: 0.5 }}
            onClick={() => { setRequestType(requestInfo); toggleDialog(); }}
            disabled={!requestInfo.active}
          >
            {requestInfo.name}
          </MenuItem>
        ))}
      </Menu>
      <DialogWithTextField
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
        title={`${requestType.name} para ${selectedInvoices.length} documentos`}
        onSubmit={raiseOperativeRequest}
        loadingSubmit={loadingMutation}
        textFieldLabel="Motivo de la solicitud"
        textInput={comment}
        setTextInput={setComment}
      />
    </>
  );
};

MenuOperativeRequest.propTypes = {
  documentType: PropTypes.string,
  clear: PropTypes.func,
  anchor: PropTypes.bool.isRequired,
  closeAnchor: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
};

MenuOperativeRequest.defaultProps = {
  selectedInvoices: [],
  clear: () => {},
  documentType: 'invoice',
};

export default MenuOperativeRequest;
